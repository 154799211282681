
:root {
  --primary-color: #2e2a2a;
  --primary-color-bg: #f1f1f1;
  --primary-color-bg-shade: rgb(233, 233, 233);
}

body {
  color: var(--primary-color);
}

input {
  font-family: 'Roboto';
}


.App {
  text-align: center;
  background-color: var(--primary-color-bg);
  padding-bottom:20px;
}

.App-header {
  position:relative;
  /* background-color: #282c34; */
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  font-size: 50px;
  font-weight: 700;
  color: var(--primary-color);

}

.App-header .logo {
  background-image: url(./svg/Bill.Split.svg);
  width: 80vw;
  height: 150px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.App-header svg {
  position: absolute;
  top: 0;
  max-height: 20vh;
  width:100%;
}


.App .container {
  margin: 0 20px;
}

.App-header .description {
  line-height: 1.4;
}

.card {
  border-radius: 11px;
  background-color: white;
  padding: 20px;
  margin-bottom: 1.50em;
}

.section-title {
  font-weight: 300;
  font-size: 10vw;
  text-align: left;
  margin-top: 0;
  margin-bottom: 0.53em;
}

.subtitle {
  font-size: 22px;
  font-weight: 300;
}

.description {
  font-size: 16px;
  font-weight: 300;
  max-width: 400px;
  white-space:break-all;
}

.price-text {
  font-size: 22px;
  font-weight: 300;
  text-align: center;
}

.add-item-form {
  display:flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--primary-color-bg-shade);
  border-radius: 11px;
}

.add-item-form input {
  height:45px;
  border:none;
  background: none;
  font-size:15px;
}

.add-item-form input[type="text"] {
  padding: 0 15px;
  width:40%
}

.add-item-form input[type="number"] {
  width:30%;
  padding: 0 15px;
}

.add-item-form input[type="submit"] {
  width: 30%;
  background: var(--primary-color);
  color: white;
  border-radius: inherit;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}


.add-people-row {
  display: flex;
  flex-direction: flex-end;
  align-items: center;
  justify-content: space-between;
}
.add-people-row p {
  flex: 1 0;
  text-align: left;
}

.add-people-row input {
  width: 45px;
  height: 45px;
  border:none;
  background: none;
  font-size: 30px;
  font-weight: 300;
  text-align: center;
}
.add-people-row .plus-minus-button {
  width: 12px;
  height: 12px;
  padding: 8px;
  margin: 5px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  color: transparent;

  pointer-events: all;
}

.add-people-row .plus-minus-button.minus {
  background-image: url(./svg/minus-black.svg);
  /* background-image: url(./svg/minus.svg); */
}

.add-people-row .plus-minus-button.plus {
  background-image: url(./svg/plus-black.svg);
  /* background-image: url(./svg/plus.svg); */
}

.item {
  height: 45px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  /* background-color: var(--primary-color-bg-shade); */
  border-radius: 11px;
  padding: 5px 15px;

  margin-bottom: 10px;
  border: 2px solid var(--primary-color-bg);

}

.item p {
  margin:0;
}

.item .name {
  width: 50%;
  text-align: left;
}

.item .price {
  width: 50%;
  text-align: left;
}

.item .delete-item {
  width: 12px;
  height: 12px;
  padding: 8px;
  background-color: transparent;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(./svg/delete-no-bg.svg);
  border:none;
  box-shadow:none;
}

.add-people-row .plus-minus-button {
  width: 12px;
  height: 12px;
  padding: 8px;
  margin: 5px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  color: transparent;

  pointer-events: all;
}

.add-people-row .plus-minus-button.minus {
  background-image: url(./svg/minus-black.svg);
  /* background-image: url(./svg/minus.svg); */
}

.add-people-row .plus-minus-button.plus {
  background-image: url(./svg/plus-black.svg);
  /* background-image: url(./svg/plus.svg); */
}

.number-input-container {
  display:flex;
  align-items:center;
  justify-content: flex-start;
}

.grid-section {
  display: grid;
  grid-template-columns: 60% 40%;
}

.grid-section .subtitle {
  text-align: left;
}

.grid-section p {
  margin: 7px 0px;
}

.action-button {
  margin-bottom: 0.50em;
  padding:0.3em 0;
  width:100%;
  
  display: flex;
  align-items: center;
  justify-content: center;
  
  color:var(--primary-color-bg);
  font-weight: 300;
  font-size:26px;
  text-transform: capitalize;
  cursor: pointer;
  
  border-radius: 11px;
  border: transparent 3px solid;
  background-color:var(--primary-color);
  
  box-sizing: border-box;
  -moz-box-sizing: border-box;

  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.action-button:hover, .action-button:active {
  color: var(--primary-color);
  border: var(--primary-color) 3px solid;
  background-color: var(--primary-color-bg-shade);
}

@media screen and (min-width: 767px) {
  .App .container {
    max-width: 767px;
    margin: 0 auto;
  }

  .App-header {
    padding-top: 10vh;
    min-height:40vh;
  }
  .section-title {
    font-weight: 300;
    font-size: 50px;
  }

  .App-header .description {
    font-size: 21px;
  }
}