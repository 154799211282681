@font-face {
  font-family: 'Roboto';
  src: local('Roboto Italic'), local('Roboto-Italic'),
      url('fonts/Roboto-Italic.woff2') format('woff2'),
      url('fonts/Roboto-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Black Italic'), local('Roboto-BlackItalic'),
      url('fonts/Roboto-BlackItalic.woff2') format('woff2'),
      url('fonts/Roboto-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Bold'), local('Roboto-Bold'),
      url('fonts/Roboto-Bold.woff2') format('woff2'),
      url('fonts/Roboto-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Black'), local('Roboto-Black'),
      url('fonts/Roboto-Black.woff2') format('woff2'),
      url('fonts/Roboto-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'),
      url('fonts/Roboto-BoldItalic.woff2') format('woff2'),
      url('fonts/Roboto-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Light Italic'), local('Roboto-LightItalic'),
      url('fonts/Roboto-LightItalic.woff2') format('woff2'),
      url('fonts/Roboto-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Light'), local('Roboto-Light'),
      url('fonts/Roboto-Light.woff2') format('woff2'),
      url('fonts/Roboto-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Medium'), local('Roboto-Medium'),
      url('fonts/Roboto-Medium.woff2') format('woff2'),
      url('fonts/Roboto-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Medium Italic'), local('Roboto-MediumItalic'),
      url('fonts/Roboto-MediumItalic.woff2') format('woff2'),
      url('fonts/Roboto-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Thin Italic'), local('Roboto-ThinItalic'),
      url('fonts/Roboto-ThinItalic.woff2') format('woff2'),
      url('fonts/Roboto-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Thin'), local('Roboto-Thin'),
      url('fonts/Roboto-Thin.woff2') format('woff2'),
      url('fonts/Roboto-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), local('Roboto-Regular'),
      url('fonts/Roboto-Regular.woff2') format('woff2'),
      url('fonts/Roboto-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

html {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


